body {
  margin: 0;
  background: #f0f2f5;
  /* font-family: 'Gantari', sans-serif; */
  font-family: 'Mulish', sans-serif;
  /* font-family: 'Nunito', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Session Expired CSS */
.main-container {
  height: 100vh;
  display: flex;
  color: #fff;
  align-items: center;
  background-color: #2D72D9;
  justify-content: center;
}