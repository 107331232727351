$medium: 500px;

.authLayout {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .victorLogo {
    width: 160px;
    margin-bottom: 45px;
  }

  .authBox {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 36px 62px 77px 58px;
    background: #FFFFFF;
    max-width: 544px;
    min-width: 300px;
    width: 100%;
    // width: calc(100vw - 40px);

    @media (max-width: $medium) {
      padding: 36px 20px 77px 20px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      div {
        display: flex;
        flex-direction: column;
        // gap: 8px;
      }

      .ant-input-affix-wrapper{
        outline: none;
        padding: 0px 11px;
        border-radius: 4px;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

        &:hover{
          border: 1px solid #E5E7EB;
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        }

        .ant-input:focus , .ant-input{
          border:none;
          box-shadow: none;
          outline: none;
        }
      }

      .ant-input-affix-wrapper-focused{
        border-color: #101820;
        border: 1px solid #101820 !important;
      }

      .ant-input{
        height: 36px;
        outline: none;
        border-radius: 4px;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

        &:focus{
          border: 1px solid #101820;
        }
      }

    }

    label {
      color: var(--cool-gray);
    }

    .error {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffe4e6b4;
      border-radius: 4px;
      color: #E11D48;
      padding: 0 10px;
      // margin: 14px 0;

      svg {
        margin-right: 15px;
      }
    }

    .success {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #93ebe4a8;
      border-radius: 4px;
      color: #0D9488;
      padding: 0 10px;

      svg {
        margin-right: 15px;
        margin-left: 5px;
      }
    }

    .authSubmit{
      height: 36px;
      margin-top: 8px;
      border-radius: 4px;
    }

  }

  .text-teal-600{
    color: #0D9488;
    font-weight: 600;
  }

  .otp-input{
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;

    Input{
      width: 40px;
      text-align: center;
      margin: 0px 7.5px;
      height: 48px !important;
      border-radius: 3px;
      width: 100%;
    }
  }

  @media screen {

  }