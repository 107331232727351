@import '~antd/dist/antd.css';

body {
  margin: 0;
  // font-family: Inter, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-dark: #101820;
  --primary-gold: #f7be00;
  --cool-gray: #6b7280;
  --cool-gray-50: #F9FAFB;
  --light-gray: #F8F8F8;
  --positive: #1d8759;
  --dark-blue: #2563eb;
  --negative: #e11d48;
  --cool-gray-400: #9ca3af;
  --disabled-btn: #F3F4F6;
}

h1 , h2 , h3 , h4 , h5 , h6 , p{
  margin-bottom: 0px;
}

.input-error{
  border-color: var(--negative) !important;
}

.input-label-error{
  color: var(--negative) !important;
}

.ant-btn{
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  &:disabled{
    background-color: var(--disabled-btn) !important;
    color: #9CA3AF !important;
  }

  &:hover , &:focus , &:active{
    color: #FFFFFF;
    border: none;
    box-shadow: none;
    outline: none;
  }

}

.ant-btn-link {
  cursor: pointer;
  color: var(--primary-dark);
}

.anticon-eye-invisible svg{
  width: 15px;
  height: 15px;
}

.bg-gray-50{
  background: #F9FAFB;
  border-radius: 4px;
}

.text-sm{
  font-size: 12px;
}

.text-grey-700{
  color: #374151;
}

.ml-10{
  margin-left: 10px;
}

.p-10{
  padding: 10px;
}

.pb-16{
  padding-bottom: 16px;
}

.flex{
  display: flex;
}

.items-center{
  align-items: center;
}

.space-between{
  justify-content: space-between;
}

.justify-center{
  justify-content: center;
}

.justify-end{
  justify-content: end;
}

.transparent-ant-button{
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  /* shadow-base */
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  color: #101820;
}

.ant-menu-light .ant-menu-item:hover{
  color: #000000;
}

.logOut-text{
  color: #f43f5e;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.info-text{
  color: #108ee9;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.green-text{
  color: #87d068;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.logOut-text:hover{
  font-weight: 700;
}

.ant-checkbox-inner{
  width: 20px;
}

.ant-checkbox + span{
  font-size: 12px;
  padding-top: 5px;
  padding-right: 0px;
}

.ant-input , .ant-input-affix-wrapper{
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-input-affix-wrapper > input.ant-input{
  border: none !important;
  box-shadow: none !important;
}

.pagination-records{
  background: #d3d3d373;
  padding: 5px 10px;
  border-radius: 4px;
}


.btn-success{
  background-color: var(--positive) !important;
  color: white !important;
}

.btn-danger{
  background-color: var(--negative) !important;
  color: white !important;
}

.btn-info{
  background-color: var(--primary-gold) !important;
  color: black !important;
}

.other-seetings .ant-card-head-title{
  padding-bottom: 5px !important;
  border-bottom: 2px solid #dfdddd;
}

.ant-switch-checked {
  background-color: var(--positive) !important;
}

.salapeLink{
  cursor: pointer;

  &:hover {
      color: var(--primary-gold);
  }
}

#rapyd-toolkit {
  width: 100%;
  margin-top: 12px;
  #rapyd-checkout-frame {
    min-height: 554px !important;
  }
}

.password_requirements{
  background-color: var(--cool-gray-50);
  border-radius: 4px;
  padding: 16px;

  h3{
      font-weight: 500;
      font-size: 16px;
      margin-bottom: .5em;
  }

  ol{
      padding-left: 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 21px;
  }
}

.viaFlex span {
  font-size: 14px;
}

.ant-radio-group {
  width: 100%;
}

.ant-input-wrapper {
  .ant-input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.ant-progress-bg {
  background-color: var(--primary-gold);
  width: 10%;
  height: 5px !important;
  border-radius: 10px;
}

.section-header-text {
  font-size: 18px;
  font-weight: 700;
}

.flex-auto {
  flex: 1 0 auto;
}

.min-h-36 {
  min-height: 36px;
}

@media screen and (min-width: 768px) {
  .right-align-options {
    max-width: 370px;
    float: right;
  }
}